/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import './index.css';

class ProjectCard extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className='project-card'>
        <img className='project-card-img' src={this.props.imgsrc} alt={this.props.imgtxt}></img>
        <hr/>
        <h2>{this.props.title}</h2>
        <hr />
        <div className='project-card-highlights'>
          {this.props.highlights.map((highlight, idx) => (
            <div key={idx} className='project-card-highlight'>{highlight}</div>
          ))}
        </div>
        <hr />
        <div className='project-card-desc'>
          {this.props.children}
        </div>
        <hr />
        <div>
          <a target="_blank" rel="noreferrer" href={this.props.linksrc}>{this.props.linktxt}</a>
        </div>
      </div>
    )
  };
};

export default ProjectCard;