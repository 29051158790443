
import React from 'react';
import Sketch from "react-p5";
import Navbar from "comps/navbar";
//import Font from "resources/UbuntuMono-Regular.otf";
import gameDevPic from "resources/imgs/gameprogramming.png";
import programmingPic from "resources/imgs/programming-codes-.png";
import fullstackDev from "resources/imgs/fullstackdev.png";

//https://editor.p5js.org/codingtrain/sketches/tYXtzNSl - Water Ripples

import './index.css';

const Home = () => {
  let cols;
  let rows;
  let current; // = new float[cols][rows];
  let previous; // = new float[cols][rows];
  let dampening = 0.99;
  let downScale = 3;
  let gfx;

  const setup = (p5, canvasParentRef) => {
    const parentRef = document.getElementById("canvas-container");
    p5.createCanvas(parentRef.clientWidth, parentRef.clientHeight, p5.P2D).parent(canvasParentRef);
    p5.pixelDensity(1);
    gfx = p5.createGraphics(Math.floor(p5.width / downScale), Math.floor(p5.height / downScale), p5.P2D);
    gfx.pixelDensity(1);
    cols = gfx.width;
    rows = gfx.height;
    current = new Array(cols).fill(0).map(n => new Array(rows).fill(0));
    previous = new Array(cols).fill(0).map(n => new Array(rows).fill(0));
  }

  const draw = (p5) => {
    p5.clear();
    gfx.background(0, 55, 22);
    gfx.loadPixels();
    for (let i = 1; i < cols - 1; i++) {
      for (let j = 1; j < rows - 1; j++) {
        current[i][j] =
          (previous[i - 1][j] +
            previous[i + 1][j] +
            previous[i][j - 1] +
            previous[i][j + 1]) /
          2 -
          current[i][j];
        current[i][j] = current[i][j] * dampening;
        // Unlike in Processing, the pixels array in p5.js has 4 entries
        // for each pixel, so we have to multiply the index by 4 and then
        // set the entries for each color component separately.
        let index = (i + j * cols) * 4;
        gfx.pixels[index + 0] = current[i][j] / (Math.random() * 32);
        gfx.pixels[index + 1] = current[i][j] / (Math.random() * 16);
        gfx.pixels[index + 2] = current[i][j] / (Math.random() * 4);
        gfx.pixels[index + 3] = current[i][j] / 32;
      }
    }
    gfx.updatePixels();
    p5.image(gfx, 0, 0, p5.width, p5.height);
    let temp = previous;
    previous = current;
    current = temp;


  }

  const resize = (p5) => {
    const parentRef = document.getElementById("canvas-container");
    p5.resizeCanvas(parentRef.clientWidth, parentRef.clientHeight);
    gfx.resizeCanvas(Math.floor(p5.width / downScale), Math.floor(p5.height / downScale));
    cols = gfx.width;
    rows = gfx.height;
    current = new Array(cols).fill(0).map(n => new Array(rows).fill(0));
    previous = new Array(cols).fill(0).map(n => new Array(rows).fill(0));
  }

  const mouseClicked = (p5) => {
    const tmouseX = Math.floor(p5.mouseX / downScale);
    const tmouseY = Math.floor(p5.mouseY / downScale);
    if (tmouseX > 0 && tmouseX < cols - 1 && tmouseY > 0 && tmouseY < rows - 1)
      previous[tmouseX][tmouseY] += 10000;
  }

  const mouseMoved = (p5) => {
    const tmouseX = Math.floor(p5.mouseX / downScale);
    const tmouseY = Math.floor(p5.mouseY / downScale);
    if (tmouseX > 0 && tmouseX < cols - 1 && tmouseY > 0 && tmouseY < rows - 1)
      previous[tmouseX][tmouseY] += 10000;
  }

  return (
    <div>
      <div id="sticky-container">
        <div id="canvas-container">
          <Sketch setup={setup} draw={draw} windowResized={resize} mouseClicked={mouseClicked} mouseMoved={mouseMoved} mouseDragged={mouseMoved} />
        </div>
      </div>
      <Navbar route="Home" />

      <div className="content-wrapper">
        <div className="home-section fadein-left" style={{ animationDelay: "0.3s" }}>
          <div className="home-section-img-container">
            <img src={programmingPic} alt='Selective Focus Code'></img>
          </div>
          <div className='home-section-content'>
            <h1>Software Development</h1>
            <h3>Realizing Solutions Through Software</h3>
            <ul className="home-section-skills">
              <li>C#</li>
              <li>WPF</li>
              <li>JavaScript</li>
              <li>Node JS</li>
              <li>React JS</li>
              <li>Electron</li>
              <li>Python</li>
              <li>Selenium</li>
              <li>AI Integration</li>
              <li>Object Oriented Programming (OOP)</li>
            </ul>
            <div id="carouselIndicators1" className="carousel" data-bs-pause="true">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselIndicators1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselIndicators1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselIndicators1" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <h5 className="d-block w-100">Empowering Efficient Media Management with Cutting-Edge Technology</h5>
                  <p className="indent d-block w-100">I've crafted a range of cutting-edge solutions for personal use and freelance projects, including a minimalist media tracker using Electron and Node JS. This wonderful tool streamlines media management, ensuring seamless access without an internet connection as a requirement. It's not just efficient - it's secure, with a knack for generating eye-catching thumbnails powered by FFMPEG.</p>
                </div>
                <div className="carousel-item">
                  <h5 className="d-block w-100">Driving Operational Excellence Through Propreitary Software and AI Integration</h5>
                  <p className="indent d-block w-100">In one remarkable project, I engineered a game-changing app for a company, streamlining the audio file tagging process down to a science. This meticulously tagged data served as the secret sauce in supercharging their AI system, leading to a whopping 60% surge in operational efficiency. My work doesn't just impress - it transforms businesses into unstoppable forces!</p>
                </div>
                <div className="carousel-item">
                  <h5 className="d-block w-100">Revolutionizing Workflows Through Automation and Streamlining</h5>
                  <p className="indent d-block w-100">I engineered a sorely needed tool for a company's QA team, revolutionizing the review and categorization of a substantial photo collection. QA personnel raved about the 150% surge in productivity, thanks to this solution.</p>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators1" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators1" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="home-section fadein-right" style={{ animationDelay: "0.6s" }}>
          <div className="home-section-img-container">
            <img src={fullstackDev} alt='Selective Focus Code'></img>
          </div>
          <div className='home-section-content'>
            <h1>Full Stack Development and Administration</h1>
            <h3>Driving Innovation with Comprehensive Full Stack Development</h3>

            <ul className="home-section-skills">
              <li>Apache</li>
              <li>Nginx</li>
              <li>Express</li>
              <li>Node JS</li>
              <li>Socket.io</li>
              <li>PHP</li>
              <li>SQL</li>
              <li>NoSQL</li>
              <li>React</li>
              <li>Vue</li>
              <li>AWS</li>
              <li>Cloudflare</li>
              <li>API Integration</li>
              <li>API Development</li>
            </ul>
            <div id="carouselIndicators2" className="carousel" data-bs-pause="true">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselIndicators2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <h5>Cutting-Edge Ghost Template Deployment and Security Implementation on AWS</h5>

                  <p className="indent">Diving deep into diverse tech landscapes, my journey as a full stack developer has been nothing short of exhilarating. Take, for instance, one of my favorite projects - a bespoke <a target="_blank" rel="noreferrer" href="https://ghost.org">Ghost</a> template hosted on an <a target="_blank" rel="noreferrer" href="https://aws.amazon.com">AWS</a> EC2 instance, seamlessly pulling images from an <a target="_blank" rel="noreferrer" href="https://aws.amazon.com">AWS</a> S3 bucket. This innovative setup empowers site owners to effortlessly update content without ever touching the HTML, JS, or Ghost template files. Fortified by a robust <a target="_blank" rel="noreferrer" href="https://aws.amazon.com">AWS</a> S3 Bucket Permission, the bucket's security is top-notch, with exclusive requests permitted solely through <a target="_blank" rel="noreferrer" href="https://www.cloudflare.com">Cloudflare</a>, ensuring top-tier protection and content delivery for our domain and IP.</p>
                </div>
                <div className="carousel-item">
                  <h5>Pioneering AI Content Generation and Workflow Automation through ChaiNNer Integration</h5>

                  <p className="indent">I've spearheaded the development of a multitude of backend tools for AI content generation, leveraging the robust capabilities of <a target="_blank" rel="noreferrer" href="https://github.com/AUTOMATIC1111/stable-diffusion-webui/">Automatic1111's Web UI for Stable Diffusion</a> to drive seamless content creation for the company's platform. Notably, one of my innovations is the Image QA tool highlighted in my software development section. Additionally, I engineered a bespoke nodes for <a target="_blank" rel="noreferrer" href="https://github.com/chaiNNer-org/chaiNNer">ChaiNNer</a>, automating tasks ranging from batch photo renaming, watermark application, and folder structuring to color normalization. Thanks to this integration, previously manual processes have been transformed into effortless automated workflows, enhancing efficiency across the board.</p>
                </div>
                <div className="carousel-item">
                  <h5>Expert Configuration of LAMP, LEMP, and MERN Stacks for Optimal Site Functionality and Security</h5>

                  <p className="indent">I've orchestrated the seamless implementation of multiple LAMP, LEMP, and MEAN stacks for diverse clientele, ensuring impeccable site configuration and fortified security measures. A standout case was the intricate deployment of a Discord bot for Twitch notifications, which I personally architected from the ground up using NodeJS and Express. Operating within a MEAN stack environment, this setup harmoniously managed multiple services, including a game server and a dedicated web page. To guarantee flawless functionality, I intricately fine-tuned the MEAN stack setup, meticulously directing domain requests to designated local ports, thereby eliminating conflicts and preventing inadvertent user misdirection. This endeavor also entailed the precise configuration of routing and firewall rules, encompassing both standard and non-standard web ports for optimal performance.</p>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators2" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="home-section fadein-left">
          <div className="home-section-img-container">
            <img src={gameDevPic} alt='Selective Focus Code'></img>
          </div>
          <div className='home-section-content'>
            <h1>Game Development</h1>
            <h3>Engaging in Innovative Game Development</h3>
            <ul className="home-section-skills">
              <li>Unity 3D</li>
              <li>Godot</li>
              <li>Unreal</li>
            </ul>
            <div>
              <h5>Mastering Game Jams: Consistent Top-Tier Performances in 48-Hour Challenges!</h5>
              <p className="indent">I have actively engaged in and released multiple games developed during a range of exhilarating game jam events. These fast-paced contests challenge individual developers or teams to craft a fully functional game within a limited 48-hour window, adhering to specific themes and guidelines unveiled at the outset. Thriving under the pressure, our team's creations have consistently ranked from the 25th percentile to achieving remarkable top-ten placements in select jam competitions!</p>

              <div id="carouselIndicators3" className="carousel" data-bs-pause="true">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselIndicators3" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselIndicators3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselIndicators3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <h5>Cryptomon - Ten Second Tamagotchi</h5>

                    <p className="indent">Download <a target="_blank" rel="noreferrer" href='https://thetornadotitan.itch.io/cryptomon'>Here</a>!</p>

                    <p className="indent">Engage in a captivating ten-second-themed game jam experience inspired by Tamagotchi. Generate revenue by tending to your virtual Cryptomon, ensuring both your and your Cryptomon's needs are met for survival.</p>

                    <p className="indent">If your character's health depletes, it results in a game over. Neglecting the needs of other players' Cryptomon accelerates the health decline.</p>

                    <p className="indent">Ensure your Cryptomon's well-being for increased profit generation. Letting their health reach 0 will lead to their demise. Neglecting other aspects of your Cryptomon's needs will hasten their health deterioration.</p>

                    <p className="indent">Operated on a ten-second tick rate, the game allows you to undertake one action for yourself and one for each of your owned Cryptomon per tick. Strive to prolong your survival and thrive if you can!</p>

                    <p className="indent">With 7 unique Cryptomon and 4 Cryptovices, a total of 28 combinations await your mastery. Can you collect them all?</p>
                  </div>
                  <div className="carousel-item">
                    <h5>Stair Fall - Gravity Platformer</h5>
                    <p className="indent">Play <a target="_blank" rel="noreferrer" href='https://thetornadotitan.itch.io/stair-fall'>Here</a>!</p>

                    <p className="indent">Embark on a thrilling gaming adventure centered around a character grappling with an overwhelming gravitational force. Endure the relentless pull of gravity for as long as you can, all while keeping a keen eye out for mischievous grandmas! Replenish your energy by devouring an array of delicious burgers and restore your health by collecting scattered hearts. Stay clear of all other obstacles to survive. Additionally, utilize elevators to escalate your levels and enhance your chances of success!</p>

                    <p className="indent">
                      Programming: Isaac Hisey<br />
                      Art & Music: Justin Gilbert<br />
                      Design/Concept: Justin Gilbert & Isaac Hisey
                    </p>
                  </div>
                  <div className="carousel-item">
                    <h5>Monster Smash - Destroy a City as Fast as Possible!</h5>
                    <p className="indent">Play <a target="_blank" rel="noreferrer" href='https://ralnier.github.io'>Here</a>!</p>

                    <p className="indent">Immerse yourself in the chaos of Monster Smash, a heart-pounding Unity 3D game that puts you in control of an unstoppable force of destruction. Employ the Space key for thrilling jumps, master the fluid movement using WASD, and activate the shift key to unleash an exhilarating sprint. Guide the monstrous entity through an array of diverse buildings, reveling in the sheer thrill of watching utter devastation unfold before your eyes! Your objective: wreck as many buildings as you can within the given time constraints.</p>

                    <p className="indent">Get ready for a whirlwind adventure in this small-scale Unity 3D project that promises colossal fun and unlimited mayhem!</p>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators3" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators3" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;