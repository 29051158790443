
import React from 'react';
import Navbar from "comps/navbar";
import './index.css';
import resume from 'resources/isaac_hisey_public_resume.pdf'

const About = () => {

  return (
    <div>
      <Navbar route="About" />
      <div className="content-wrapper">
        <h1>Info:</h1>
        <div className="bold">Isaac Hisey</div>
        <div className="italicize indent">Versatile Software Developer | Data Analyst | Problem-Solver | Innovator</div>
        <hr />
        <h1>Objective:</h1>
        <p>I'm a passionate software developer specializing in crafting bespoke and innovative solutions. With a strong foundation in JavaScript and C#, I've honed my skills by working with diverse technologies and frameworks. I've leveraged the power of JavaScript in various contexts, using Node.js for backend development and crafting dynamic user interfaces with React and Vue.</p>
        <p>In the realm of C#, I've designed solutions using WPF, showcasing my commitment to creating robust desktop applications. My experience extends beyond coding — I've deployed websites ranging from locally hosted hardware with fully custom Nginx setups to containerized AWS EC2 instances, ensuring efficient and scalable solutions.</p>
        <p>Driven by a desire to excel, I am dedicated to personal and professional growth as a software developer. My goal is to contribute to the delivery of excellence, whether it's enhancing user experiences, advancing healthcare outcomes for patients, or meeting the unique needs of customers.</p>
        <hr />
        <h1>Stay Connected:</h1>
        <p className="stay-conntect-social">
          <a target='_blank' rel='noreferrer' href="https://www.linkedin.com/in/isaac-hisey/" class="stay-conntect-social">
            <span className="fa fa-linkedin"></span>
            <span>https://www.linkedin.com/in/isaac-hisey/</span>
          </a>
          <a target='_blank' rel='noreferrer' href="https://github.com/thetornadotitan" class="stay-conntect-social">
            <span className="fa fa-github"></span>
            <span>https://github.com/thetornadotitan</span>
          </a>
          <a target='_blank' rel='noreferrer' href="https://www.facebook.com/isaachisey/" class="stay-conntect-social">
            <span className="fa fa-facebook"></span>
            <span>https://www.facebook.com/isaachisey/</span>
          </a>
        </p>
        <div className='center'><a target="_blank" rel="noreferrer" href={resume}>Public Resumé</a></div>
      </div>
    </div>
  );
};

export default About;