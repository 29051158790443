import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/home';
import About from 'pages/about';
import Project from 'pages/projects'
import 'resources/shared.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Project />} />
      </Routes>
    </Router>
  );
}

export default App;