
import React from 'react';
import Navbar from "comps/navbar";
import ProjectCard from 'comps/project_card';
import labellerimg from 'resources/imgs/labeller.png'
import './index.css';

const Projects = () => {
  return (
    <div>
      <Navbar route="Projects" />

      <div className="content-wrapper center">
        <h1>Project Highlights</h1>
        <div className='project-card-container'>
          <ProjectCard
            imgsrc="https://user-images.githubusercontent.com/22167677/147900037-01cf57b8-8a77-4b57-9aab-89c4854097fd.png"
            imgtxt="Project Banner"
            title="Media Tracker"
            highlights={[
              "Purpose Built",
              "Daily Use",
              "Electron",
              "React",
              "Node JS",
              "JavaScript"
            ]}
            linksrc="https://github.com/thetornadotitan/media_tracker_react_electron"
            linktxt="Github Page"
          >
            This application holds significant personal value for me. I created this media tracker to monitor my watched and unwatched media. Although I was aware of Plex and Jellyfin's existence at the time, I didn't require an entire media server, considering it excessive. Additionally, I aimed to gain hands-on experience in utilizing React and Electron to develop my own media tracker. As a result, this application came into existence. Having been used daily for approximately three years, this project stands as a source of personal pride for me.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://steamuserimages-a.akamaihd.net/ugc/888756449958422283/E4B7235AF650BA904118B894765A62A9F4D7D2CC/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true"
            imgtxt="Project Banner"
            title="Missle Defence - Wallpaper Engine"
            highlights={[
              "JavaScript",
              "Wallpaper Engine",
              "Steam Workshop",
              "211 Subscribers"
            ]}
            linksrc="https://steamcommunity.com/sharedfiles/filedetails/?id=1150502306"
            linktxt="Steam Workshop Page"
          >
            I crafted this project for Wallpaper Engine, an application enabling dynamic, moving desktop backgrounds. My initial fascination was in creating personalized backgrounds. Interestingly, my first idea was a self-playing game of Missile Defense utilizing steering behaviors to guide the rockets. I implemented it using JavaScript, and it served as my desktop background for an extended period.
          </ProjectCard>

          <ProjectCard
            imgsrc={labellerimg}
            imgtxt="Project Banner"
            title="Audio Tagging Software"
            highlights={[
              "C# - WPF",
              "Freelance Work",
              "Audio Tagging",
              "Custom Tool"
            ]}
          >
            Presented here is a screencap showcasing the culmination of my inaugural freelance assignment. Unfortunately, a link to the code isn't provided as it remains proprietary to the contracting company. For this project, I employed C# and WPF in conjunction with audio libraries to affix custom tags on audio files with per-sample accuracy. This implementation significantly enhanced the company's workflows, enabling the utilization of emerging tools to bolster work accuracy. A piece of feedback received upon project completion resonates: "It's been great working with you, and we may reach out if we need another tool built!" - conveyed by a representative from the company.
          </ProjectCard>
        </div>

        <hr />

        <h1>Publicly Released Games</h1>
        <div className='project-card-container'>
          <ProjectCard
            imgsrc="https://img.itch.zone/aW1nLzk3NzcxMTMucG5n/original/VJxWaO.png"
            imgtxt="Game Banner"
            title="Stair Fall"
            highlights={[
              "First Game Jam",
              "Made in 2 days",
              "Collaboration",
              "6th place overall",
              "Godot Engine",
              "C#"
            ]}
            linksrc="https://thetornadotitan.itch.io/stair-fall"
            linktxt="Itch.io Page"
          >
            Embark on a thrilling gaming adventure centered around a character grappling with an overwhelming gravitational force. Endure the relentless pull of gravity for as long as you can, all while keeping a keen eye out for mischievous grandmas! Replenish your energy by devouring an array of delicious burgers and restore your health by collecting scattered hearts. Stay clear of all other obstacles to survive. Additionally, utilize elevators to escalate your levels and enhance your chances of success!
            <hr />
            Created within a 2 day timeframe for Pixel Jam 2 by Isaac Hisey and Justin Gilbert.
            <hr />
            Programming: Isaac Hisey<br />
            Art & Music: Justin Gilbert<br />
            Design/Concept: Justin Gilbert & Isaac Hisey
          </ProjectCard>

          <ProjectCard
            imgsrc="https://img.itch.zone/aW1nLzEzODI2Mjg4LnBuZw==/347x500/WJGXMW.png"
            imgtxt="Game Banner"
            title="Cryptomon"
            highlights={[
              "Second Game Jam",
              "Beat ~74% of entries",
              "620 of ~2.5k entries",
              "Collaboration",
              "Godot Engine",
              "C#"
            ]}
            linksrc="https://thetornadotitan.itch.io/cryptomon"
            linktxt="Itch.io Page"
          >
            Make money by taking care of your Cryptomon. Survive by meeting your own and your Cryptomons' needs.<br /><br />
            Your character will die if their health reaches 0. If other players' needs are neglected, health will drop more rapidly.<br /><br />
            Crypotmon produce more money when they are well taken care of. Cryptomon will die if you let their health reach 0. If other needs of Cryptomon are neglected, health will drop more<br /><br />
            The game runs on a tick rate of every 10 seconds. You can take one action for yourself and one for each of the Crytomon you own - per tick. Survive as long as possible. Thrive if<br /><br />
            There are 7 Cryptomon and 4 Cryptovices, 28 combinations! Can you get them all?
          </ProjectCard>
        </div>

        <hr />

        <h1>Game Modding</h1>
        <div className='project-card-container'>
          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/3174/images/3503/3503-1639844417-1914924311.jpeg"
            imgtxt="Mod Banner"
            title="Kaoses Tweaks - Bannerlord"
            highlights={[
              "Endorsements: 2,053",
              "Unique DLs: 70,300",
              "Total DLs: 128,109",
              "Total views: 384,612"
            ]}
            linksrc="https://www.nexusmods.com/mountandblade2bannerlord/mods/3503?tab=desc"
            linktxt="Nexus Mods"
          >
            I took a role in the Bannerlord mod community, focusing on the revival of a popular mod to align with the latest Mount & Blade II: Bannerlord version. My collaboration with developers led to the incorporation of new features and fixes. The documentation of my contributions on the GitHub repository provides a tangible record of my technical input and dedication to community-driven initiatives. This experience underscores my adaptability and commitment to collaborative efforts within the game modding community.
            <hr />
            A tip of the hat too:  <br />
            Arcanak, CollinHerber, Ocu87, Igor-Rakhmanov, cburch824 for their contributions<hr />
            Big shout-out to: <br />
            MinhazMurks who took over to keep the ball rolling until the original developer came back.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/110/images/14267-1-1333443582.jpg"
            imgtxt="Mod Banner"
            title="More and Indefinite Mining - Skyrim"
            highlights={[
              "Endorsements: 2,059",
              "Unique DLs: 21,047",
              "Total DLs: 47,018",
              "Total views: 153,066"
            ]}
            linksrc="https://www.nexusmods.com/skyrim/mods/14267?tab=description"
            linktxt="Nexus Mods"
          >
            This Skyrim mod enhances the ore mining experience, allowing players to mine indefinitely at an accelerated rate. Designed to be compatible with the Dragonborn DLC through the Gyldenhul Dragonborn Mining Fix by SeanGamer, the mod draws inspiration from similar concepts in other mods but features a script entirely of my creation, utilizing Bethesda source files from the Creation Kit. The installation process is straightforward, either manual or through Nexus Mod Manager. A note: if you've previously entered a mine or mined an ore vein, the new script may require entering a new mine, starting a new game, or waiting for ore veins to respawn to take effect, as highlighted by community input on the Nexus Mods page.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/101/images/35810-4-1290806387.jpg"
            imgtxt="Mod Banner"
            title="Isaacs Crafting Mod - Oblivion"
            highlights={[
              "One of my first mods",
              "Originally uploaded in 2010!"
            ]}
            linksrc="https://www.nexusmods.com/oblivion/mods/35810?tab=description"
            linktxt="Nexus Mods"
          >
            This Skyrim mod enhances the ore mining experience, allowing players to mine indefinitely at an accelerated rate. Designed to be compatible with the Dragonborn DLC through the Gyldenhul Dragonborn Mining Fix by SeanGamer, the mod draws inspiration from similar concepts in other mods but features a script entirely of my creation, utilizing Bethesda source files from the Creation Kit. The installation process is straightforward, either manual or through Nexus Mod Manager. A note: if you've previously entered a mine or mined an ore vein, the new script may require entering a new mine, starting a new game, or waiting for ore veins to respawn to take effect, as highlighted by community input on the Nexus Mods page.
          </ProjectCard>
        </div>
      </div>
    </div >
  );
};

export default Projects;